import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {SearchService} from '../../../services/search.service';
import {Subscription} from 'rxjs';
import {LangUtilService} from '../../util/lang-util.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnChanges, OnInit, OnDestroy {

  public searchText: string = '';
  public placeholderValue: string;
  public searchedInfoValue$ = this.searchService.searchedInfoValue;
  // public showInfoIcon: boolean;
  //public showSearchIcon: boolean;
  private searchPlaceholderValueSub: Subscription;
  private resetSearchTextValueSub: Subscription;
  private searchDurationSub: Subscription;
  private debouncedHandleInputChange;
  @Input() resetSearchTextToSearchBox: any;
  @Input() placeholderValueInput: string;
  @Input() id: string;
  @Input() isPlaceholderTranslationNotReq: boolean;
  public showSearchDuration$ = this.searchService.isSearchDurationNeeded;
  toolTip = ''
  filterValues = [{ label: 'Search For Last', items: [{ label: '30 Days', id: 30 }, { label: '60 Days', id: 60 }, { label: '90 Days', id: 90 }, { label: 'All', id: 0 }] }]
  searchDurationModel;

  constructor(private searchService: SearchService, private lang: LangUtilService, private confirmationService: ConfirmationService) {
    this.searchText = '';
    this.placeholderValue = this.lang.getTranslation('sys.search');
    // this.showInfoIcon = true;
    // this.showSearchIcon = false;
  }

  ngOnInit() {
    this.placeholderValue = this.placeholderValueInput;
    this.searchPlaceholderValueSub = this.searchService.searchPlaceholderValue.subscribe(placeholderValue => {
      if (placeholderValue) {
        this.placeholderValue = placeholderValue;
        this.placeholderValueInput ? (this.placeholderValue = this.placeholderValueInput) : (this.placeholderValue = placeholderValue);
      }
    });
    this.resetSearchTextValueSub = this.searchService.resetSearchTextValue.subscribe(value => {
      if (value !== 0) {
        this.searchText = '';
        ((this.id) ? (this.searchService.sendSearchText(this.searchText, this.id)) : (this.searchService.sendSearchText(this.searchText)));
        // this.searchService.sendSearchText(this.searchText);
      }
    });

    this.searchDurationSub = this.searchService.searchDuration.subscribe(value => {
      if(value?.searchDuration || value?.searchDuration === 0){
        this.searchDurationModel = value.searchDuration;
      }else{
        this.searchDurationModel = value;
      }
      this.toolTip = this.searchDurationModel ? `Last ${this.searchDurationModel} Days Search` : 'All Time Search';
      let constructedObj = { label: `${this.searchDurationModel} Days`, id: this.searchDurationModel };
      this.filterValues = this.filterValues.map(group => {
        if (group.label === 'Search For Last') {
          const map = new Map(group.items.map(item => [item.id, item]));
          if (this.searchDurationModel) {
            map.set(constructedObj.id, constructedObj);
          }
          return {
            ...group,
            items: Array.from(map.values()).sort((a: any, b: any) => {
              if (a.id === 0) return 1;
              if (b.id === 0) return -1;
              return a.id - b.id;
            })
          };
        }
        return group;
      });
    });

  }

  ngOnChanges() {
    if (this.resetSearchTextToSearchBox) {
      // this.showInfoIcon = false;
      // this.showSearchIcon = true;
      if (this.resetSearchTextToSearchBox === '/asset') {
        // this.showInfoIcon = true;
        // this.showSearchIcon = false;
      }
    }
  }

  ngOnDestroy() {
    this.searchPlaceholderValueSub?.unsubscribe();
    this.resetSearchTextValueSub?.unsubscribe();
    this.searchDurationSub?.unsubscribe();
  }

  searchForSelectedText() {
    ((this.id) ? (this.searchService.sendSearchText(this.searchText, this.id)) : (this.searchService.sendSearchText(this.searchText)));
  }

  handleInputChange(event) {
    if (event.target.value != null) {
      if (!this.debouncedHandleInputChange) {
        this.debouncedHandleInputChange = this.hanldeInputDebounce(() => {
          this.searchForSelectedText();
          this.debouncedHandleInputChange = null;
        }, 700);
      }
      this.debouncedHandleInputChange();
    }
  }

  hanldeInputDebounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  clearSearchedText() {
    this.searchText = '';
    (this.id ? (this.searchService.sendSearchText(this.searchText, this.id)) : (this.searchService.sendSearchText(this.searchText)));
    // this.searchService.sendSearchText(this.searchText);
  }

  getLangLabelForSearch(labelCode: string) {
    if (labelCode) {
      if (!this.isPlaceholderTranslationNotReq) {
        const labelCodeArray = labelCode.split(' ');
        if (labelCodeArray.length > 1) {
          return this.lang.getTranslation(labelCodeArray[1]);
        } else {
          return labelCode;
        }
      } else {
        return labelCode;
      }
    }
  }

  confirm(event) {
    this.confirmationService.confirm({
      target: event.target,
      key: 'duration-search-popup',
      accept: () => {
        (this.searchService.sendSearchDurationValue(this.searchDurationModel))
      },
      reject: () => {

      }
    })
  }

  clearSearchedDuration() {
    (this.searchService.sendSearchDurationValue(null))
  }

  handleFilterChange(event) {
    this.searchDurationModel = event.value
    this.toolTip = `Last ${event.value} Days Search`
      if (event.value == 'all') {
        ((this.id) ? (this.searchService.sendSearchDurationValue(0, this.id)) : (this.searchService.sendSearchDurationValue(0)));
      } else {
        ((this.id) ? (this.searchService.sendSearchDurationValue(this.searchDurationModel, this.id)) : (this.searchService.sendSearchDurationValue(this.searchDurationModel)));
      }
  }

}
