/**
 * Created by chandru on 17/1/20.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../services/config.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpConfigService {
  constructor(private http: HttpClient, public configService: ConfigService) { }

  getHelpConfigOnModuleLevel(moduleType: string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `help/?moduleType=${moduleType}`).pipe(
      catchError(error => {
          console.error('Error', error);
          // Handle the error appropriately, e.g., show a user-friendly message
          return of([]); // Or throw error
      })
  );
  }

  getHelpConfigOnModuleLevelWithPageName(moduleType: string, page: string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `help/?moduleType=${moduleType}&page=${page}`).pipe(
      catchError(error => {
          console.error('Error ', error);
          // Handle the error appropriately, e.g., show a user-friendly message
          return of([]); // Or throw error
      })
  );
  }

  updateHelpConfigOnModuleLevel(moduleType: string, page: string, payload) {
    return this.http.put(this.configService.appConfig.appBaseUrl + `help/moduleType/${moduleType}/page/${page}`, payload);
  }

  getAllPagesForAllModules(moduleType:string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `help/pages?moduleType=${moduleType}&pagesToCreate=true`);
  }

  deletePageWithId(id:string,module:string){
    return this.http.delete(this.configService.appConfig.appBaseUrl + `help/moduleType/${module}/page/${id}`);
  }

}

